import styled from "styled-components"
import Colors from "src/ui/colors"
import Img from "gatsby-image"

const Styled = {
  Clients: styled.div`
    padding: 35px;
    text-align: center;
    background: ${Colors.offWhite};

    @media (max-width: 950px) {
      padding: 35px 0;
    }

    p {
      text-transform: uppercase;
      font-weight: 600;
      font-size: 20px;
      margin-bottom: 35px;
    }

    .BrainhubCarousel__arrows {
      background: #bbb;

      @media (max-width: 950px) {
        display: none;
      }

      &:hover {
        background: ${Colors.primaryBlue};
      }
    }
  `,

  Logos: styled.div`
    padding: 35px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;
  `,

  ClientLogo: styled(Img)<any>`
    margin: 0 30px;
    height: 45px;
    width: 100%;
  `,
}

export default Styled
