import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

// Styles
import Styled from "./capabilities.styled"
import Title from "../typography/title.component"

const Capabilities = () => {
  const data = useStaticQuery(graphql`
    query {
      partnerLogos: allFile(
        filter: {
          relativeDirectory: { eq: "partners" }
          extension: { regex: "/(png)/" }
        }
      ) {
        ...PartnerLogos
      }

      services: allMarkdownRemark(
        filter: { fileAbsolutePath: { regex: "/(services)/" } }
      ) {
        ...Services
      }
    }
  `)

  const renderServices = () => {
    const services = data.services.edges[0].node.frontmatter.services

    return services.map(service => <li key={service.name}>{service.name}</li>)
  }

  const renderPartnerLogos = () =>
    data.partnerLogos.edges.map(edge => {
      const imageData = edge.node

      return (
        <Styled.PartnerLogo key={imageData.name}>
          <Img fluid={imageData.childImageSharp.fluid} />
        </Styled.PartnerLogo>
      )
    })

  return (
    <Styled.Capabilities>
      <Styled.Section>
        <Styled.Title level={2}>Capabilities</Styled.Title>
        <div>
          <ul>{renderServices()}</ul>
        </div>
      </Styled.Section>
      <Styled.Section>
        <Styled.Title level={2}>Partnerships</Styled.Title>
        <Styled.PartnerLogos>{renderPartnerLogos()}</Styled.PartnerLogos>
      </Styled.Section>
    </Styled.Capabilities>
  )
}

export default Capabilities
