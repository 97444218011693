import React from "react"

// Components
import { Button, Container } from "components/common"

// Assets
import HeroIllustration from "images/Hero-Illustration.svg"

// Styles
import Styled from "./header.styled"

const Hero = () => {
  return (
    <Styled.Hero>
      <Styled.Container flex flexDirection="row">
        <img src={HeroIllustration} alt="Tech man walking" />
        <Styled.Text>
          <h1>
            Empowering Your Business with Cutting-Edge AI and Cloud Solutions
          </h1>
          <Button to="/work-with-us" primary>
            Let's work together
          </Button>
        </Styled.Text>
      </Styled.Container>
    </Styled.Hero>
  )
}

export default Hero
