import styled from "styled-components"
import Colors from "src/ui/colors"
import Img from "gatsby-image"

const Styled = {
  Testimonials: styled.section`
    padding: 0 100px 100px;
    text-align: center;

    @media (max-width: 950px) {
      padding: 0 25px 50px;
      text-align: left;
    }
  `,

  ClientLogo: styled(Img)<any>`
    margin: 50px auto 0;
    display: block;

    @media (max-width: 950px) {
      width: 100px;
    }
  `,
}

export default Styled
