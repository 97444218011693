import styled from "styled-components"
import Colors from "src/ui/colors"
import Title from "../typography/title.component"

const Styled = {
  Capabilities: styled.section`
    background: ${Colors.primaryBlue};
    color: ${Colors.white};
    padding: 100px;
    justify-content: center;

    @media (max-width: 950px) {
      padding: 50px 25px;
    }
  `,

  Section: styled.div`
    display: flex;
    justify-content: space-between;
    max-width: 1400px;
    width: 100%;
    margin: 0 auto 100px;

    @media (max-width: 950px) {
      flex-direction: column;
    }

    &:last-child {
      margin-bottom: 0;
    }

    > div {
      flex: 1 1 100%;
    }

    ul {
      list-style: none;
      column-count: 2;
      column-width: 200px;
      column-gap: 50px;

      li {
        font-size: 24px;
        margin-bottom: 15px;

        @media (max-width: 950px) {
          font-size: 20px;
        }
      }
    }
  `,

  Title: styled(Title)`
    color: ${Colors.white};

    @media (max-width: 950px) {
      margin-bottom: 25px;
    }
  `,

  PartnerLogos: styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 20px;

    @media (max-width: 1075px) {
      grid-template-rows: repeat(2, 1fr);
      grid-template-columns: repeat(2, 1fr);
      text-align: left;
    }
  `,

  PartnerLogo: styled.div`
    width: 100%;
    max-width: 125px;
    min-width: 100px;
    margin-right: auto;

    @media (max-width: 975px) {
      width: 40%;
      text-align: left;
    }
  `,
}

export default Styled
