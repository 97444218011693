import React from "react"
import Img from "gatsby-image"

// Components
import { Text } from "src/components/typography"
import { FadeIn } from "src/components/common"

// Styles
import Styled from "./testimonials.styled"

const Testimonials = ({ testimonial }) => {
  const { text, client, clientLogo } = testimonial
  const imageData = clientLogo.childImageSharp.fluid

  return (
    <Styled.Testimonials>
      <FadeIn>
        <Text italic>{text}</Text>
        <Styled.ClientLogo alt={`${client} logo`} fluid={imageData} />
      </FadeIn>
    </Styled.Testimonials>
  )
}

export default Testimonials
