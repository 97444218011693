import React, { useState, useEffect } from "react"
import { useStaticQuery, graphql } from "gatsby"
import Carousel from "@brainhubeu/react-carousel"

// Components
import { Container } from "src/components/common"

// Styles
import "@brainhubeu/react-carousel/lib/style.css"
import Styled from "./clients.styled"

/*

  !: Lighthouse score on landing page is between 80 and 90.

  Similar issue:

  - https://github.com/gatsbyjs/gatsby/issues/20493

  Possible fixes:

  - https://medium.com/@kyle.robert.gill/ridiculously-easy-image-optimization-with-gatsby-js-59d48e15db6e

*/

const Clients = () => {
  const data = useStaticQuery(graphql`
    query {
      allFile(
        sort: { fields: [name], order: ASC }
        filter: {
          relativeDirectory: { eq: "client-logos" }
          extension: { regex: "/(png)/" }
        }
      ) {
        ...PartnerLogos
      }
    }
  `)

  const renderClientLogos = () =>
    data.allFile.edges.map(({ node }) => {
      return node.childImageSharp ? (
        <Styled.ClientLogo
          fluid={node.childImageSharp.fluid}
          imgStyle={{
            objectFit: "contain",
          }}
          key={node.name}
        />
      ) : null
    })

  // ?: Switch to this carousel -- https://react-slick.neostack.com/docs/example/responsive/
  // Current: https://github.com/brainhubeu/react-carousel
  return (
    <Styled.Clients>
      <Container>
        <p>Those who trusted us</p>
        <Carousel
          autoPlay={3000}
          breakpoints={{
            750: {
              slidesPerPage: 2,
            },
          }}
          arrows
          infinite
          slidesPerPage={5}
          slidesPerScroll={1}
          stopAutoPlayOnHover
        >
          {renderClientLogos()}
        </Carousel>
      </Container>
    </Styled.Clients>
  )
}

export default Clients
