import styled from "styled-components"
import Colors from "ui/colors"
import { Container } from "components/common"

const Styled = {
  Hero: styled.header`
    background: ${Colors.primaryBlue};
    display: flex;
    padding: 0 65px 65px;

    @media (max-width: 1165px) {
      padding: 65px 65px 0;
    }

    @media (max-width: 950px) {
      padding: 50px 35px 0;
    }

    img {
      position: relative;
      top: 95px;
      margin-right: 50px;
      max-width: 85%;

      @media (max-width: 1165px) {
        max-width: 100%;
        top: 0;
        margin: 0;
      }
    }
  `,

  Container: styled(Container)`
    @media (max-width: 1165px) {
      flex-direction: column-reverse;
      align-items: center;
      justify-content: center;
    }
  `,

  Text: styled.div`
    color: ${Colors.white};
    position: relative;
    top: 160px;

    @media (max-width: 1165px) {
      text-align: center;
      top: 0;
      margin-bottom: 50px;
    }
    @media (max-width: 950px) {
      text-align: left;
    }

    h1 {
      letter-spacing: 2px;
      line-height: 1.2;
      font-size: 58px;
      font-weight: normal;
      margin-bottom: 30px;

      @media (max-width: 950px) {
        font-size: 42px;
        letter-spacing: 0;
      }
    }
  `,
}

export default Styled
